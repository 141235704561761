import * as React from 'react';
import dateFormat from 'dateformat'
import Paper from '@material-ui/core/Paper';
import {
  TreeDataState, SortingState, PagingState,
  IntegratedPaging, IntegratedSorting, SearchState
} from '@devexpress/dx-react-grid';
import { sentenceCase, downloadContracts } from "../utils";
import { rowToProvider } from "../adapters";
import {
  Grid,
  Table, TableHeaderRow, SearchPanel,
  PagingPanel, Toolbar as TableToolbar
} from '@devexpress/dx-react-grid-material-ui';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';

import ContractBanner from './contractBanner'
import Loading from './loading'
import Fab from './fab';
import { api } from '../api'

const getStyles = (error) => {
  return {
    "borderColor": error ? "red" : "#ACAEB9"
  }
}

const dateSorter = (a, b) => Date.parse(a) > Date.parse(b) ? 1 : -1
const providerSorter = (a, b) => a.provider_name.toLowerCase() > b.provider_name.toLowerCase() ? 1 : -1

class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      columns: [
        { name: 'name', title: 'Contract ID'},
        // { name: 'fiscal_year', title: 'Fiscal Year'},
        { name: 'provider', title: 'Provider'},
        { name: 'purpose', title: 'Purpose'},
        { name: 'start', title: 'Start' },
        { name: 'end', title: 'End' },
        { name: 'program', title: 'Program'},
        { name: 'button', title: 'Remove'},
      ],
      rows: [],
      tableColumnExtensions: [
        { columnName: 'name', align: 'left', width: 175},
        { columnName: 'fiscal_year', wordWrapEnabled: true, align: 'left', width: 130},
        { columnName: 'start', wordWrapEnabled: true, align: 'left', 'width': 95},
        { columnName: 'end', wordWrapEnabled: true, align: 'left', 'width': 95},
        { columnName: 'provider', wordWrapEnabled: true, align: 'left'},
        { columnName: 'purpose', wordWrapEnabled: true, align: 'left'},
        { columnName: 'program', width: 210},
        { columnName: 'button', align: 'center',  width: 120}
      ],
      loadingRow: null,
      programValues: {},
      errorRow: null,
      currentRows: [],
      recentlyCompleted: '',
      showRecentlyCompleted: false,
      recentlyCompletedID: null
    };
  }

  componentDidMount(){
    if (this.props.contracts[0]) {
      const rows = this.contractsResponseToRows();
      this.setState({
        loading: false,
        rows: rows,
        currentRows: rows
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.contracts[0] && prevProps.contracts !== this.props.contracts) {
      const rows = this.contractsResponseToRows();
      this.setState({
        loading: false,
        rows: rows,
        currentRows: rows
      })
    }
  }

  contractsResponseToRows = () => {
    return this.props.contracts.map(c => ({
      id: c.contract_id,
      name: c.contract_number,
      purpose: c.purpose,
      provider: rowToProvider({data: c}),
      fiscal_year: c.fiscal_year,
      start: dateFormat(c.start_date, "mm/dd/yyyy"),
      end: dateFormat(c.end_date, "mm/dd/yyyy")
    }));
  }

  postContract(contractId, programId, status) {
    const method = "POST"
    const body = JSON.stringify({
      contract: {
        contract_id: contractId,
        program_id: programId,
        status: status
      }
    })
    return api(this.props.unauthorize, "contract", method, body)
    .then( res => {
      if (!res.status) {
        return this.setState({
          error: res.errors.message,
          errorRow: contractId,
          loadingRow: null,
          recentlyCompleted: '',
          showRecentlyCompleted: false,
          recentlyCompletedID: null
        })
      } else {
        var updatedState = {
          error: null,
          loading: false
        }
        if (status === "PENDING") {
          updatedState.recentlyCompleted = ''
          updatedState.showRecentlyCompleted = false
          updatedState.recentlyCompletedID = null
        }
        this.setState(updatedState, () => {
          this.props.updateContractsAndSites();
        })
      }
    })
  }

  updateContract(r, deliversServices, programId) {
    let status = deliversServices ? 'ACTIVE' : 'DELETED';
    const programName = deliversServices ? this.props.programs.find(p => p.program_id === parseInt(programId)).program_name : ""
    this.postContract(r.id, programId, status)
      .then( () => !this.state.error ? this.displayBanner(r.name, r.provider.provider_name, r.id, deliversServices, programName) : "")
  }

  displayBanner(contractName, providerName, contractID, deliversServices, programName) {
    if (this.bannerTimer) {
      clearTimeout(this.bannerTimer)
    }
    var message = deliversServices ? `Contract ${contractName} with ${providerName} has been successfuly assigned to ${programName}.` : `Contract ${contractName} with ${providerName} has been removed from the list of contracts.`;
    this.setState({
      recentlyCompleted: message,
      showRecentlyCompleted: true,
      recentlyCompletedID: contractID
    }, () => this.setBannerTimer())
  }

  setBannerTimer = () => this.bannerTimer = setTimeout(() => {this.setState({showRecentlyCompleted: false})}, 15000)

  handleUndo = () => {
    this.postContract(this.state.recentlyCompletedID, null, "PENDING")
  }

  errorMessage() {
    if (this.state.error) {
      return (
        <div className="text-center p-2 my-3 bg-primary-red-30t border border-primary-red animated fadeInUp">
          {this.state.error}
        </div>
      );
    }
  }

  updateRows(value) {
    const term = value.toLowerCase();
    if (!term) {
      return this.setState({
        currentRows: this.state.rows
      });
    }
    this.setState({
      currentRows: this.state.rows.filter(r => {
        return r.name.toLowerCase().includes(term) || r.provider.provider_name.toLowerCase().includes(term) || r.purpose.toLowerCase().includes(term);
      })
    })
  }

  render() {
    if (this.state.loading) {
        return (
          <div className="mt-4">
            <Loading />
          </div>
        );
    }
    const columns = this.state.columns;
    const rows = this.state.currentRows;
    const Cell = (props) => {
      const { column, row } = props;
      if (column.name === 'button') {
        return (
          <Table.Cell {...props}>
          <div className="mx-2">
            {this.state.loadingRow !== row.id &&
              <div>
              <Fab
                onClick={() => {this.updateContract(row, false)}}
                icon={<DeleteIcon fontSize='small'/>}
              />
              </div>
            }
            {this.state.loadingRow === row.id &&
              <div className="mr-6 flex items-center px-2 primary-blue-70t">
                <svg className="spinner icon-4" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <circle className="spinner__path" cx="12" cy="12" r="10" fill="none"></circle>
                </svg> &nbsp;&nbsp; Loading...
              </div>
            }
          </div>
          </Table.Cell>
        );
      }
      if (column.name === 'provider') {
        return (
          <Table.Cell {...props}>
            <div>
              <Link className="" to={{
                pathname: "/provider",
                state: {
                  provider: row.provider
                }
              }}>
                {row.provider.provider_name}
              </Link>
            </div>
          </Table.Cell>
        );
      }
      if (column.name === 'program') {
        return (
          <Table.Cell {...props}>
              <select
                style={getStyles(this.state.errorRow === row.id)}
                value={this.state.programValues[row.id]}
                defaultValue={""}
                id={row.id + "-programSelect"}
                className="select border"
                onChange={event => {
                  event.preventDefault();
                  const programId = event.target.value;
                  this.updateContract(row, true, programId);
                }}>

                <option value={""} disabled>  select program...  </option>
                {this.props.programs.map(p => {
                  return <option key={p.program_id} value={p.program_id}>{p.program_name}</option>
                })}
              </select>
          </Table.Cell>
        );
      }
      if (column.name === 'purpose') {
        return (
          <Table.Cell {...props}>
            <div>
              {sentenceCase(row.purpose)}
            </div>
          </Table.Cell>
        )
      }
      return <Table.Cell {...props} />;
    };

    const HeaderComponent = ({children, row, ...restProps}) => {
      switch(restProps.tableColumn.column.name) {
        case 'button':
        return <TableHeaderRow.Cell className="text-lg border-b-2 border-t-1">
          <span className="text-lg w-full text-center">{restProps.tableColumn.column.title}</span>
        </TableHeaderRow.Cell>
        default:
        return <TableHeaderRow.Cell {...restProps} className="text-lg border-b-2 border-t-1">
          <span className="text-lg w-full text-left">{children}</span>
        </TableHeaderRow.Cell>
      }
    }

    return (
      <div>
        <ContractBanner
          recentlyCompleted={this.state.recentlyCompleted}
          showRecentlyCompleted={this.state.showRecentlyCompleted}
          handleUndo={this.handleUndo.bind(this)}
        />
        <div className="flex mb-2">
          <div className="w-1/2">
            <h2 className="font-bold">New Contracts</h2>
            <a href={"mailto:" + process.env.REACT_APP_ADD_PROGRAM_EMAIL}>Request new program be added to dropdown</a>
          </div>
          <div className="w-1/2">
            <div className="float-right mt-16">
              <button className="o-navigation__link outline-none btn color-primary-button mt-2" onClick={downloadContracts.bind(this, this.props.contracts, `sssld_contracts_${Date.now()}`)}>Download</button>
            </div>
          </div>
        </div>
        {this.errorMessage()}

        <Paper className="border" >
          <Grid
            rows={rows}
            columns={columns}
            getRowId={(r) => r.id}
          >
            <PagingState
              defaultCurrentPage={0}
              pageSize={10}
            />
            <TreeDataState />
            <SortingState
              columnExtensions={[{
                columnName: 'name',
                sortingEnabled: true
              },{
                columnName: 'provider',
                sortingEnabled: true
              },{
                columnName: 'purpose',
                sortingEnabled: false
              },{
                columnName: 'program',
                sortingEnabled: false
              },{
                columnName: 'button',
                sortingEnabled: false
              }]}
            />
            <SearchState onValueChange={(value) => {
              this.updateRows(value)
            }} />

            <IntegratedSorting               
              columnExtensions={
                [
                  {
                    columnName: 'provider',
                    compare: providerSorter
                  }, 
                  {
                    columnName: 'start',
                    compare: dateSorter
                  },
                  {
                    columnName: 'end',
                    compare: dateSorter
                  }
                ]
              }
            />
            <IntegratedPaging />

            <Table
              cellComponent={Cell}
              columnExtensions={this.state.tableColumnExtensions}
            />

            <PagingPanel />

            <TableHeaderRow
              showSortingControls
              cellComponent={HeaderComponent}
            />
            <TableToolbar />
            <SearchPanel />


          </Grid>
        </Paper>
      </div>
    );
  }
}

export default Contracts;
